<template>
    <header
        id="js-top-header"
        class="tw--mx-4 tw-top-0 tw-z-30 tw-relative tw-sticky"
    >
        <div class="tw-h-full">
            <div
                id="js-live-price-ticker"
                class="gbp lazyload lazyload-no-fade live-price-ticker tw-flex tw-items-center tw-justify-center tw-text-center tw-w-full tw-px-1 tw-bg-chards-silver-white tw-font-sans tw-text-xxs sm:tw-text-sm"
            >
                <div
                    id="js-price-container"
                    class="tw-flex tw-items-center tw-w-11/12"
                >
                    <template
                        v-for="(metal, index) in metals"
                        :key="index"
                    >
                        <LivePriceElement
                            :show="pricesResolved"
                            :name="metal.name"
                            :href="metal.href"
                            :price="currencyStore.formatPriceWithCurrency(metal.current)"
                            :element="metal.element"
                            :description="metal.description"
                        />
                    </template>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { useCurrencyStore } from "@stores/currencyStore";
import LivePriceElement from '@header/atoms/LivePriceElement.vue';
import { mapStores } from "pinia";

export default {
    name: 'LivePriceTicker',
    components: {
        LivePriceElement
    },
    data() {
        return {
            pricesResolved: false,
            metals: {
                gold: {
                    name: 'Gold',
                    element: 'Au',
                    current: 0,
                    href: '/gold-price/gold-spot-price',
                    description: 'Live Gold Price'
                },
                silver: {
                    name: 'Silver',
                    element: 'Ag',
                    current: 0,
                    href: '/silver-price/silver-spot-price',
                    description: 'Live Silver Price'
                },
                platinum: {
                    name: 'Platinum',
                    element: 'Pt',
                    current: 0,
                    href: '/platinum-price/ounce/gbp/today',
                    description: 'Live Platinum Price'
                },
                palladium: {
                    name: 'Palladium',
                    element: 'Pd',
                    current: 0,
                    href: '/palladium-price/palladium-price-last-week',
                    description: 'Live Palladium Price'
                }
            }
        };
    },
    computed: {
        ...mapStores(useCurrencyStore)
    },
    mounted() {
        this.subscribeToStore();
        this.currencyStore.getLivePricingData();
        this.currencyStore.startUpdatingLivePricingData();
    },
    unmounted() {
        this.currencyStore.stopUpdatingLivePricingData();
    },
    methods: {
        setLiveMetalData(metalPrices) {
            this.pricesResolved = false;
            Object.keys(metalPrices).forEach(key => {
                if (this.metals[key]) this.metals[key].current = metalPrices[key].current;
            });
            this.pricesResolved = true;
        },
        subscribeToStore() {
            this.currencyStore.$onAction(({name, store, after}) => {
                if (name === 'getLivePricingData') {
                    after(() => {
                        this.setLiveMetalData(store.metalPrices);
                    });
                }
            });
            this.currencyStore.$subscribe((mutation) => {
                if (mutation.type === 'patch object' && mutation.payload.currencyCode) {
                    this.currencyStore.getLivePricingData();
                }
            });
        }
    }
};
</script>
