<template>
    <AppLink
        id="js-gold-live-price"
        :href="href"
        size="xxsmall"
        tone="chards-dark"
        classes="live-prices tw-group tw-flex tw-items-center tw-justify-center tw-py-1 tw-w-3/12 sm:tw-text-sm max-md:tw-flex-col hover:tw-no-underline"
        :aria-label="description"
    >
        <div class="group-hover:tw-underline tw-text-chards-purple tw-text-xxs sm:tw-text-base tw-px-5">
            {{ name }}
        </div>
        <div class="content tw-text-chards-purple tw-flex tw-flex-col tw-items-center md:max-lg:tw-flex-row">
            <div
                v-show="show"
                id="js-gold-current-price"
                class="group-hover:tw-no-underline sm:tw-px-2.5"
            >
                {{ price }} / oz
            </div>
        </div>
    </AppLink>
</template>

<script>
import AppLink from '@app/atoms/AppLink.vue';

export default {
    name: 'LivePriceElement',
    components: {
        AppLink
    },
    props: {
        name: {
            type: String,
            default: '',
            required: true
        },
        element: {
            type: String,
            default: '',
            required: true
        },
        price: {
            type: String,
            default: ''
        },
        href: {
            type: String,
            default: '',
            required: true
        },
        show: {
            type: Boolean,
            required: true
        },
        description: {
            type: String,
            default: '',
            required: true
        }
    }
};
</script>
